/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { formatLocale, FormatLocaleDefinition } from 'd3-format';
import { RegistryWithDefaultKey, OverwritePolicy } from '../models';
import createD3NumberFormatter from './factories/createD3NumberFormatter';
import createSmartNumberFormatter from './factories/createSmartNumberFormatter';
import NumberFormats from './NumberFormats';
import NumberFormatter from './NumberFormatter';

function roundOffNumber(value: number) {
  const rounded_number = String(value.toFixed(2));
  const ar_rounded_number = rounded_number.split('.');
  return ar_rounded_number[1]
    ? ar_rounded_number[1] === '00'
      ? ar_rounded_number[0]
      : rounded_number
    : rounded_number;
}

function roundOffDecimalNumber(value: number, decimal_count: number) {
  const rounded_number = String(value.toFixed(decimal_count));
  const ar_rounded_number = rounded_number.split('.');
  return ar_rounded_number[1]
    ? ar_rounded_number[1] === '0'
      ? ar_rounded_number[0]
      : rounded_number
    : rounded_number;
}

function convertNumberToText(value: number) {
  let number_formatted = `${value}`;
  if (value) {
    if (value >= 1000 && value < 100000) {
      number_formatted = `${roundOffNumber(value / 1000)} K`;
    } else if (value >= 100000 && value < 10000000) {
      number_formatted = `${roundOffNumber(value / 100000)} Lac`;
    } else if (value >= 10000000) {
      number_formatted = `${roundOffNumber(value / 10000000)} Cr`;
    } else {
      number_formatted = roundOffNumber(value);
    }
  }
  return number_formatted;
}

function createD3IndianCurrencyTextFormatter(config: {
  description?: string;
  formatString: string;
  label?: string;
  locale?: FormatLocaleDefinition;
}) {
  const { description, formatString, label, locale } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    if (typeof locale !== 'undefined') {
      formatLocale(locale).format(formatString);
    }
    formatFunc = (value: number) => `₹ ${convertNumberToText(value)}`;
  } catch (error) {
    formatFunc = (value: number) =>
      `${value} (Invalid format: ${formatString})`;

    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label,
  });
}

function createD3NumberToIndianNumeralTextFormatter(config: {
  description?: string;
  formatString: string;
  label?: string;
  locale?: FormatLocaleDefinition;
}) {
  const { description, formatString, label, locale } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    if (typeof locale !== 'undefined') {
      formatLocale(locale).format(formatString);
    }
    formatFunc = (value: number) => `${convertNumberToText(value)}`;
  } catch (error) {
    formatFunc = (value: number) =>
      `${value} (Invalid format: ${formatString})`;

    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label,
  });
}

function convertNumberToDurationText(value: number) {
  const decimal_count = 1;
  let number_formatted = `${value}`;
  if (value) {
    if (value < 60) {
      number_formatted = `${roundOffDecimalNumber(value, decimal_count)} Min`;
    } else if (value === 60) {
      number_formatted = `${roundOffDecimalNumber(
        value / 60,
        decimal_count,
      )} Hr`;
    } else if (value > 60 && value < 1440) {
      number_formatted = `${roundOffDecimalNumber(
        value / 60,
        decimal_count,
      )} Hrs`;
    } else if (value === 1440) {
      number_formatted = `${roundOffDecimalNumber(
        value / 1440,
        decimal_count,
      )} Day`;
    } else if (value > 1440) {
      number_formatted = `${roundOffDecimalNumber(
        value / 1440,
        decimal_count,
      )} Days`;
    } else {
      number_formatted = roundOffDecimalNumber(value, decimal_count);
    }
  }
  return number_formatted;
}

function createD3DurationINHoursDaysFormatter(config: {
  description?: string;
  formatString: string;
  label?: string;
  locale?: FormatLocaleDefinition;
}) {
  const { description, formatString, label, locale } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    if (typeof locale !== 'undefined') {
      formatLocale(locale).format(formatString);
    }
    formatFunc = (value: number) => `${convertNumberToDurationText(value)}`;
  } catch (error) {
    formatFunc = (value: number) =>
      `${value} (Invalid format: ${formatString})`;

    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label,
  });
}

export default class NumberFormatterRegistry extends RegistryWithDefaultKey<
  NumberFormatter,
  NumberFormatter
> {
  constructor() {
    super({
      name: 'NumberFormatter',
      overwritePolicy: OverwritePolicy.WARN,
    });

    this.registerValue(
      NumberFormats.SMART_NUMBER,
      createSmartNumberFormatter(),
    );
    this.registerValue(
      NumberFormats.SMART_NUMBER_SIGNED,
      createSmartNumberFormatter({ signed: true }),
    );

    this.registerValue(
      NumberFormats.INDIAN_CURRENCY_TEXT,
      createD3IndianCurrencyTextFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['₹ ', ''],
        },
        formatString: '$,.0f',
      }),
    );

    this.registerValue(
      NumberFormats.NUMBER_TO_INDIAN_NUMERAL_TEXT,
      createD3NumberToIndianNumeralTextFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['', ''],
        },
        formatString: '$,.0f',
      }),
    );

    this.registerValue(
      NumberFormats.INDIAN_CURRENCY_NUMBER,
      createD3NumberFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['₹ ', ''],
        },
        formatString: '$,.0f',
      }),
    );

    this.registerValue(
      NumberFormats.DURATION_IN_HOUR_DAYS,
      createD3DurationINHoursDaysFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['t ', ''],
        },
        formatString: '$,.0f',
      }),
    );

    this.setDefaultKey(NumberFormats.SMART_NUMBER);
  }

  get(formatterId?: string) {
    const targetFormat = `${
      formatterId === null ||
      typeof formatterId === 'undefined' ||
      formatterId === ''
        ? this.defaultKey
        : formatterId
    }`.trim();

    if (this.has(targetFormat)) {
      return super.get(targetFormat) as NumberFormatter;
    }

    // Create new formatter if does not exist
    const formatter = createD3NumberFormatter({
      formatString: targetFormat,
    });
    this.registerValue(targetFormat, formatter);

    return formatter;
  }

  format(
    formatterId: string | undefined,
    value: number | null | undefined,
  ): string {
    return this.get(formatterId)(value);
  }
}
